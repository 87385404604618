<template>
  <div :id="modalId" :class="classes" :style="this.styles" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('auth.email_not_verified')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="resendVerificationEmail">
          <div class="modal-body">
            <p>{{$t('auth.email_not_verified_text')}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" @click="hide">{{$t('generic-str.cancel')}}</button>
            <button
              :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >{{$t('auth.resend_email')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import Modal from '@/mixins/Modal';

const webApi = axios();

export default {
  mixins: [Modal],
  props: ['id', 'email'],
  data() {
    return {
      isSending: false,
    };
  },
  methods: {
    resendVerificationEmail() {
      this.isSending = true;
      webApi.post('/auth/resend-email', { email: this.email }).then((response) => {
        this.hide();
        this.$toast.show({
          title: this.$t('auth.resend_success'),
          content: this.$t('auth.resend_success_text'),
          type: 'success',
        });
        this.isSending = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var{
  position: relative !important;
}
.emoji-color{
  color: #ccd2dc !important;
}
.actions-var-cancel{
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234,243,253,.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon{
  font-size: 14px !important;
}
.actions-var a{
  cursor: pointer;
}
</style>
